import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';

import { Question } from '../../interfaces/question.interface';
import { Answer } from '../../interfaces/answer.interface';
import { InclusionConfiguration } from '../../interfaces/inclusionConfiguration.interface';
import { createInclusionConfiguration, resetInclusionConfiguration } from '../../app/store/inclusionConfigurationSlice';

import { DataRow, DataCell } from '../Tables/DataList';
import Typography from '@mui/material/Typography';
import AutocompleteSearch from '../Forms/AutoCompleteSearch';
import Grid from '@mui/material/Grid';
import { renderOption } from './LogicListItem';
import { FormControl, FormLabel, InputLabel, MenuItem, Select, Switch } from '@mui/material';
import { fetchAll } from '../../app/store/questionnaireSlice';


export default function NewLogicMapping(props: {
  canEdit?: boolean,
  updateQuestion: any,
  inclusionList?: InclusionConfiguration[],
  handleClose: any,
}) {
  const dispatch = useAppDispatch();
  const { inclusionConfiguration } = useAppSelector(state => state.inclusionConfigurations);
  const { results: questionnaires, questionnaire } = useAppSelector(state => state.questionnaires);
  const [logicQuestion, setLogicQuestion] = useState<Question | undefined>(undefined);

  const [questionnaireId, setQuestionnaireId] = useState<number | string | undefined>(questionnaire?.id);
  const [fetchUnpublished, setFetchUnpublished] = useState(false);

  const onQuestionSelect = (question:Question) => {
    setLogicQuestion(question);
  };

  const answerSelect = (answer:Answer) => {
    dispatch(createInclusionConfiguration({ question: logicQuestion?.id, answer: answer.id }));
  };

  useEffect(() => {
    if (inclusionConfiguration && 'id' in inclusionConfiguration) {
      const newInclusions = props.inclusionList ? [ ...props.inclusionList, inclusionConfiguration] : [ inclusionConfiguration ];
      props.updateQuestion({ inclusions: newInclusions });
      dispatch(resetInclusionConfiguration());
      setLogicQuestion(undefined);
      props.handleClose();
    }
  }, [inclusionConfiguration]);

  useEffect(() => {
    dispatch(fetchAll(fetchUnpublished));
  }, [fetchUnpublished]);

  return (
    <>
      { !logicQuestion && <>
        <Grid container gap={2}>
          <Grid item xs={12}>
            {!!questionnaires?.length && <>
              <FormControl fullWidth>
                <InputLabel id="questionnaire-label">Questionnaire</InputLabel>
                <Select
                  labelId="questionnaire-label"
                  id="questionnaire"
                  value={questionnaireId}
                  label="Questionnaire"
                  disabled={props.canEdit === false}
                  onChange={(e) => setQuestionnaireId(e.target.value)}
                >
                  {questionnaires.map(q=> <MenuItem key={q.id} value={q.id}>{q.title} {q.version}</MenuItem>)}
                </Select>
              </FormControl>
              <FormLabel>Show Unpublished Questionnaires
                <Switch onChange={() => setFetchUnpublished(!fetchUnpublished)} checked={!fetchUnpublished} />
              </FormLabel>
            </>}
          </Grid>
          <Grid item xs={12}>
            <AutocompleteSearch 
              onChangeHandler={onQuestionSelect}
              api='questions'
              label='Search for Question'
              renderOption={renderOption}
              filter={{ questionnaireId }}
            />
          </Grid>
        </Grid>
      </>}
      { logicQuestion &&
        <>
          <Typography mb={2}>Question: {logicQuestion.title}</Typography>
          { logicQuestion.answers.length > 0 ?
            <Grid container>
              { logicQuestion.answers.map((answer: Answer) => (
                <DataRow key={answer.id} p={1} mb={.01} onClick={() => answerSelect(answer)} hover>
                  <DataCell xs={12} p={1}>{answer.text}</DataCell>
                </DataRow>
              ))}
            </Grid>
            :
            <Typography>No Answers Available</Typography>
          }
        </>
      }
    </>
  );
}