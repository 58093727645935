import axios from 'axios';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import getAuthSession from '../../services/auth';
import { InclusionConfiguration, InclusionConfigurationResultArray } from '../../interfaces/inclusionConfiguration.interface';
import { FieldValues } from 'react-hook-form';

interface InitialInclusionConfigurationsState {
  count: number;
  next?: string;
  previous?: string;
  inclusionConfiguration?: InclusionConfiguration;
  results: InclusionConfiguration[];
  deletedInclusionConfigurationId?: number;
  error?: any;
}

const initialState = {
  count: 0,
  next: undefined,
  previous: undefined,
  inclusionConfiguration: undefined,
  results: [],
  deletedInclusionConfigurationId: undefined,
  error: undefined,
} as InitialInclusionConfigurationsState;

const baseUrl = process.env.REACT_APP_BASE_API;

export const fetchAll = createAsyncThunk(
  'inclusionConfigurations/fetchAll',
  async () => {
    const authSession = await getAuthSession();
    try {
      const response = await axios.get(
        `${baseUrl}/inclusion-configurations/`,
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return (await response.data) as InclusionConfigurationResultArray;
    } catch (error: any) {
      return error;
    }
  },
);

export const fetchInclusionConfiguration = createAsyncThunk(
  'inclusionConfigurations/fetchInclusionConfiguration',
  async (inclusionConfigurationId: number, { rejectWithValue }) => {
    try {
      const authSession = await getAuthSession();
      const response = await axios.get(
        `${baseUrl}/inclusion-configurations/${inclusionConfigurationId}/`,
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return response.data as InclusionConfiguration;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const createInclusionConfiguration = createAsyncThunk(
  'inclusionConfigurations/createInclusionConfiguration',
  async ({ question, answer }: FieldValues, { rejectWithValue }) => {
    try {
      const authSession = await getAuthSession();
      const response = await axios.post(
        `${baseUrl}/inclusion-configurations/`, {
          question,
          answer,
        },
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return (await response.data) as InclusionConfiguration;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const deleteInclusionConfiguration = createAsyncThunk(
  'inclusionConfigurations/deleteInclusionConfiguration',
  async (inclusionConfigurationId: number, { rejectWithValue }) => {
    try {
      const authSession = await getAuthSession();
      await axios.delete(
        `${baseUrl}/inclusion-configurations/${inclusionConfigurationId}/`,
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return inclusionConfigurationId;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const updateInclusionConfiguration = createAsyncThunk(
  'inclusionConfigurations/updateInclusionConfiguration',
  async ({ id, question, answer }: FieldValues, { rejectWithValue }) => {
    try {
      const authSession = await getAuthSession();
      const response = await axios.patch(
        `${baseUrl}/inclusion-configurations/${id}/`, {
          id,
          question,
          answer,
        },
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return response.data as InclusionConfiguration;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

const inclusionConfigurationSlice = createSlice({
  name: 'inclusionConfigurations',
  initialState,
  reducers: {
    resetInclusionConfiguration: (state) => {
      state.inclusionConfiguration = undefined;
      state.error = undefined;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchAll.fulfilled, (state, action: PayloadAction<InclusionConfigurationResultArray>) => {
      state.count = action.payload.count;
      state.next = action.payload.next;
      state.previous = action.payload.previous;
      state.results = action.payload.results.sort((a, b) => a.id - b.id);
    });
    builder.addCase(createInclusionConfiguration.fulfilled, (state, action: PayloadAction<InclusionConfiguration>) => {
      state.results = [...state.results, action.payload];
      state.inclusionConfiguration = action.payload;
    });
    builder.addCase(createInclusionConfiguration.rejected, (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    });
    builder.addCase(deleteInclusionConfiguration.fulfilled, (state, action: PayloadAction<number>) => {
      state.error = null;
      state.deletedInclusionConfigurationId = action.payload;
      state.results = state.results.filter((inclusionConfiguration: InclusionConfiguration) => inclusionConfiguration.id !== action.payload);
    });
    builder.addCase(updateInclusionConfiguration.fulfilled, (state, action: PayloadAction<InclusionConfiguration>) => {
      state.results = state.results.filter((inclusionConfiguration: InclusionConfiguration) => inclusionConfiguration.id !== action.payload.id);
      state.results = [...state.results, action.payload].sort((a, b) => a.id - b.id);
      state.error = null;
      state.inclusionConfiguration = action.payload;
    });
    builder.addCase(updateInclusionConfiguration.rejected, (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    });
    builder.addCase(fetchInclusionConfiguration.fulfilled, (state, action: PayloadAction<InclusionConfiguration>) => {
      state.inclusionConfiguration = action.payload;
    });
  },
});

export const { resetInclusionConfiguration } = inclusionConfigurationSlice.actions;

export default inclusionConfigurationSlice.reducer;