import axios from 'axios';
import { InclusionConfigurationResultArray } from '../interfaces/inclusionConfiguration.interface';
import getAuthSession from './auth';

export default async function getIncluisionConfigurationsById(inclusionConfigIds:number[]) {
  const authSession = await getAuthSession();
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_API}/inclusion-configurations/?ids=${inclusionConfigIds.join(',')}`,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as InclusionConfigurationResultArray;
}