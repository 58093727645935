import React, { useMemo } from 'react';
import { Box, LinearProgress, Tooltip, Typography } from '@mui/material';

import { useAppSelector } from '../../app/hooks';
import { BestPracticeStatus } from '../../interfaces/survey.interface';
import { getAssessmentQuestionsAndAnswersCount, getSurveyQuestionsAndAnswersCount } from '../../services/surveyHelpers';

type SurveyAssessmentProgressProps = {
  onClick?: () => void;
};

const SurveyAssessmentProgress = ({ onClick }: SurveyAssessmentProgressProps) => {
  const { survey } = useAppSelector((state) => state.surveys);
  const { assessment } = useAppSelector((state) => state.assessments);
  const { assessmentStatus, assessmentQuestionsStatus, questionnaire, bpStatus } = useAppSelector(
    (state) => state.questionnaireAnswer,
  );

  const showProgressBar = () => {
    if (assessmentStatus) {
      return assessmentStatus.toLowerCase() === 'assessing';
    }

    return survey && survey.status !== 'submittedForAssessment';
  };

  const progressValues = useMemo(() => {
    if (!showProgressBar() || (!assessment && !survey)) return;

    let questionsCount = 0;
    let answersCount = 0;

    if (assessment) {
      const { questionsCount: qCount, answersCount: aCount } = getAssessmentQuestionsAndAnswersCount(
        bpStatus,
        assessmentQuestionsStatus,
        questionnaire,
      );
      questionsCount = qCount;
      answersCount = aCount;

      return {
        answersCount,
        questionsCount,
        percent: (answersCount / questionsCount) * 100,
      };
    }

    const { questionsCount: sqCount, answersCount: saCount } = getSurveyQuestionsAndAnswersCount(
      survey?.bestPracticeStatuses as BestPracticeStatus[],
    );
    questionsCount = sqCount;
    answersCount = saCount;

    return {
      answersCount,
      questionsCount,
      percent: (answersCount / questionsCount) * 100,
    };
  }, [showProgressBar, assessment, survey, assessmentQuestionsStatus, questionnaire, bpStatus]);

  if (!showProgressBar()) return null;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', maxWidth: '90%', mx: 'auto', columnGap: '0.5rem' }}>
      <Box sx={{ position: 'relative', flexGrow: 1 }}>
        <Tooltip title={`${progressValues?.percent.toFixed()}%`}>
          <LinearProgress
            variant='determinate'
            color='primary'
            value={progressValues?.percent}
            onClick={onClick}
            sx={{ height: '1.25rem', borderRadius: '1rem', cursor: 'pointer' }}
          />
        </Tooltip>
      </Box>
      <Typography fontWeight='500'>{`${progressValues?.answersCount}/${progressValues?.questionsCount}`}</Typography>
    </Box>
  );
};

export default SurveyAssessmentProgress;
