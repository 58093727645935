import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';

import IconButton from '@mui/material/IconButton';
import { DataRow, DataCell, DataRowActions } from '../Tables/DataList';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import StandardDialog, { StandardDialogActions } from '../Modals/StandardDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import { Question } from '../../interfaces/question.interface';
import { InclusionConfiguration } from '../../interfaces/inclusionConfiguration.interface';
import { deleteInclusionConfiguration } from '../../app/store/inclusionConfigurationSlice';

import axios from 'axios';
import getAuthSession from '../../services/auth';

import { renderOption } from '../Logic/LogicListItem';
import NewLogicMapping from '../Logic/NewLogicMapping';

export default function LogicMapping(props: {
  topText?: string,
  description?: string,
  inclusions?: InclusionConfiguration[],
  updateBP: any,
  inclusionDeletedCallback: any
}) {
  const baseUrl = process.env.REACT_APP_BASE_API;
  const dispatch = useAppDispatch();
  const { deletedInclusionConfigurationId } = useAppSelector(state => state.inclusionConfigurations);

  const [inclusionsList, setInclusionsList] = useState<InclusionConfiguration[] | undefined>(undefined);
  const [delInclusion, setDelInclusion] = useState<InclusionConfiguration | undefined>(undefined);

  const [deleteLogicOpen, setDeleteLogicOpen] = useState(false);
  const handleDeleteLogicOpen = (inclusion:InclusionConfiguration) => {
    setDelInclusion(inclusion);
    setDeleteLogicOpen(true);
  };
  const handleDeleteLogicClose = () => {
    setDelInclusion(undefined);
    setDeleteLogicOpen(false);
  };

  const [addLogicOpen, setAddLogicOpen] = useState(false);
  const handleAddLogicOpen = () => setAddLogicOpen(true);
  const handleAddLogicClose = () => {
    setAddLogicOpen(false);
  };

  const onDeleteSubmit = (inclusion:InclusionConfiguration) => {
    dispatch(deleteInclusionConfiguration(inclusion.id));
  };

  useEffect(() => {
    if (deletedInclusionConfigurationId) {
      let logIndex = inclusionsList?.findIndex((inclusion) => inclusion.id === deletedInclusionConfigurationId);
      let newInclusions = inclusionsList ? inclusionsList : [];
      if (logIndex !== undefined) {
        props.inclusionDeletedCallback(deletedInclusionConfigurationId);
        newInclusions.splice(logIndex, 1);
        setInclusionsList(newInclusions);
      } 
      handleDeleteLogicClose();
    }
  }, [deletedInclusionConfigurationId]);

  const getInclusions = async (newInclusions?:InclusionConfiguration[]) => {
    if (!props.inclusions && !newInclusions) return setInclusionsList([]);

    const authSession = await getAuthSession();
    const promises:Array<any> = [];
    if (newInclusions) {
      newInclusions.forEach(inclusion => {
        promises.push(axios.get(
          `${baseUrl}/questions/${typeof inclusion.question === 'number' ? inclusion.question : inclusion.question.id}/`,
          {
            headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
          },
        ));
      });
    } else if (props.inclusions) {
      props.inclusions.forEach(inclusion => {
        promises.push(axios.get(
          `${baseUrl}/questions/${typeof inclusion.question === 'number' ? inclusion.question : inclusion.question.id}/`,
          {
            headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
          },
        ));
      });
    }
    
    axios
      .all(promises)
      .then(res => {
        const questions:Question[] = res.map(question => question.data);
        let inclusions:InclusionConfiguration[] = [];

        if (newInclusions) {
          newInclusions?.forEach(inclusion => {
            inclusions.push({ ...inclusion, question: questions.find(question => typeof inclusion.question !== 'number' && question.id === inclusion.question.id)! });
          });
        } else {
          props.inclusions?.forEach(inclusion => {
            inclusions.push({ ...inclusion, question: questions.find(question => typeof inclusion.question !== 'number' && question.id === inclusion.question.id)! });
          });
        }
        setInclusionsList(inclusions);
      });
  };

  useEffect(() => {
    getInclusions();
  }, [props.inclusions]);

  return (
    <>
      { props.topText && <Typography mb={1}>{props.topText}</Typography> }
      { props.description && <Typography mb={1}>{props.description}</Typography> }
      <Box mb={2} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography component="h6">Parent Logic (Display if):</Typography>
        <Button sx={{ marginLeft: '2em' }} size='large' variant='contained' color='primary' type="submit" startIcon={<AddIcon />} onClick={handleAddLogicOpen}>Add Logic</Button>
        <StandardDialog title='Add New Logic' handleClose={() => handleAddLogicClose()} isOpen={addLogicOpen}>
          <NewLogicMapping
            handleClose={() => handleAddLogicClose()}
            updateQuestion={props.updateBP}
            inclusionList={inclusionsList!}
          />
        </StandardDialog>
      </Box>
      { inclusionsList?.length! > 0 &&
        <Box sx={{ backgroundColor: '#F3F5FB', borderRadius: 1, position: 'relative', '&:after': {
          content: '""',
          background: '#F3F5FB',
          position: 'absolute',
          bottom: '8px',
          height: '1px',
          left: '0',
          right: '0',
        } }} pb={1} mb={2}>
          <DataRow p={1} mb={.01} header>
            <DataCell xs={8} p={1}>Question</DataCell>
            <DataCell xs={4} p={1}>Answer</DataCell>
          </DataRow>
          { inclusionsList?.map((inclusion: InclusionConfiguration) => (
            <DataRow key={`${typeof inclusion.answer !== 'number' && inclusion.answer.text}-${inclusion.id}`} p={1} mb={.01}>
              <DataCell xs={8} p={1}>{typeof inclusion.question !== 'number' && renderOption(inclusion.question)}</DataCell>
              <DataCell xs={4} p={1}>{typeof inclusion.answer !== 'number' && inclusion.answer.text}
                <DataRowActions>
                  <IconButton size="small" name="Delete Logic" onClick={(e)=> {e.preventDefault(); handleDeleteLogicOpen(inclusion); }}><DeleteIcon /></IconButton>
                </DataRowActions>
              </DataCell>
            </DataRow>
          ))}
          <StandardDialog title="Remove Logic from question" handleClose={handleDeleteLogicClose} isOpen={deleteLogicOpen}>
            { delInclusion && <>
              <Typography>
                Are you sure you want to remove {typeof delInclusion.question !== 'number' && delInclusion.question.title}: {typeof delInclusion.answer !== 'number' && delInclusion.answer.text}?
              </Typography>
              <StandardDialogActions>
                <Button variant="outlined" onClick={() => handleDeleteLogicClose()}>Cancel</Button>
                <Button type="submit" sx={{ ml: 2 }} variant="contained" color="error" onClick={() => onDeleteSubmit(delInclusion)}>Remove</Button>
              </StandardDialogActions>
            </>}
          </StandardDialog>
        </Box>
      }
    </>
  );
}