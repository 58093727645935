import React from 'react';
import { Questionnaire } from '../interfaces/questionnaire.interface';
import { BestPracticeStatus, Survey } from '../interfaces/survey.interface';
import User, { CompanyCertification } from '../interfaces/users.interface';
import { statuses } from './questionnaireQuestionStatus';
import { AssessmentQuestionStatusById, BPStatusById, PaletteKey } from '../app/store/questionnaireAnswerSlice';
import { appTheme } from '../features/Themes/light';
import { Workflow } from '../interfaces/workflow.interface';
import { AssessmentSurvey } from '../interfaces/assessment.interface';
import BPRemediationCircles from '../features/Assessments/BPRemediationCircles';
import { Typography, LinearProgress, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import ChatIcon from '@mui/icons-material/Chat';
import ViewIcon from '@mui/icons-material/Visibility';
import RateReviewIcon from '@mui/icons-material/RateReview';
import AutoFixNormalIcon from '@mui/icons-material/AutoFixNormal';

export const assessmentSteps = [
  'Site/App Baseline In Progress',
  'Site/App Baseline Complete',
  'Best Practices Questionnaire In Progress',
  'Self-Reporting Complete',
  'Assessment Assigned',
  'Pre-Assessment In Progress',
  'Assessment In Progress',
  'Assessment Submitted for Review',
  'Assessment Completed pending Remediation',
  'Assessment Complete',
];

export const REMEDIATION_OPTIONS = {
  remediated: 'Remediated',
  willNotRemediate: 'Will not Remediate',
  willRemediateLater: 'Will Remediate Later',
};

export const SURVEY_ACTIONS_LABELS = {
  pending: 'Begin Survey',
  selfAttested: 'Begin Survey',
  reviewAndSubmit: 'Review and Submit',
  scheduleAssessment: 'Schedule Assessment',
  updateAssessment: 'Update Assessment',
  viewRemediations: 'View Remediations',
  viewQuestionnaire: 'View Questionnaire',
  continueQuestionnaire: 'Continue Questionnaire',
};

const filterCompanyCert = (props: { site?: number, application?: number, companyCerts?: CompanyCertification[] }) => {
  if (!props.companyCerts || (!props.site && !props.application)) return false;
  if (props.site) return props.companyCerts.some(ccert => ccert.sites.some(s => s.id === props.site));
  if (props.application) return props.companyCerts.some(ccert => ccert.applications.some(s => s.id === props.application));
  return false;
};

export const getSurveyQuestionsAndAnswersCount = (bpStatuses?: BestPracticeStatus[]) => {
  if (!bpStatuses || bpStatuses.length === 0) {
    return { questionsCount: 0, answersCount: 0 };
  }

  return bpStatuses.reduce((acc, obj) => ({
    questionsCount: acc.questionsCount + ((obj?.status?.toLowerCase() !== 'hide' ? (obj?.visibleQuestionsCount || 0) : 0)),
    answersCount: acc.answersCount + (obj?.questionsAnsweredCount || 0),
  }), { questionsCount: 0, answersCount: 0 });
};

export const surveyDisplayLogic = (props: { survey?: Survey, companyCerts?: CompanyCertification[], workflow?: Workflow }) => {
  const {
    survey,
    companyCerts,
    workflow,
  } = props;
  if (!workflow) return '-';
  if (workflow?.status === 'submittedForAssessment') return 'Self-Reporting Complete';
  if (workflow?.status?.toLowerCase() === 'complete') return 'Complete';
  if (!survey) return 'Pending';
  const hasCompanyCert = workflow?.bpSurvey ? filterCompanyCert({ site: workflow.site, application: workflow.applicationVersion as number, companyCerts }) : false;
  if (!survey.bestPracticeStatuses || survey.bestPracticeStatuses.length === 0) return hasCompanyCert ? 'Self-Attested' : 'Pending';
  const { questionsCount, answersCount } = getSurveyQuestionsAndAnswersCount(survey?.bestPracticeStatuses as BestPracticeStatus[]);

  if (questionsCount === 0 || answersCount === 0) return hasCompanyCert ? 'Self-Attested' : 'Pending';
  if (questionsCount !== answersCount) return  (
    <>
    <Typography>{(survey.questionnaire as Questionnaire).title}</Typography>
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexGrow: 1, paddingTop: '5px' }}>
  <LinearProgress
    variant="determinate"
    value={(answersCount / questionsCount) * 100}
    sx={{
      height: '8px',
      borderRadius: '1rem',
      width: '100%',
      color: 'primary',
    }}
  />
  <Typography sx={{ minWidth: 'fit-content', fontSize: '11px' }}>{Math.round((answersCount / questionsCount) * 100)}%</Typography>
</Box>
  </>
  );
  return survey.status;
};

export const assessmentDisplayLogic = (status:string) => {
  switch (status) {
    case 'Assigned':
    // Shouldnt be needed, but fallback just in case
    case 'submittedForAssessment':
      return assessmentSteps[4];
    case 'Pre-Assessment':
      return assessmentSteps[5];
    case 'Assessing':
      return assessmentSteps[6];
    case 'Submitted':
      return assessmentSteps[7];
    case 'Assessment Complete Pending Remediation':
      return assessmentSteps[8];
    case 'Complete':
    case 'Completed':
      return assessmentSteps[9];
    case 'Rejected':
      return 'Rejected by Assessor';
    default:
      return status;
  }
  
};

export const surveyActionLabel = (type: string, status: string) => {
  if (status === '-') return;
  if (status.toLowerCase() === 'pending' || status.toLowerCase() === 'self-attested') return `Begin ${type}`;
  if (status.toLowerCase() === 'complete') return SURVEY_ACTIONS_LABELS.reviewAndSubmit;
  if (status === 'Self-Reporting Complete') return SURVEY_ACTIONS_LABELS.scheduleAssessment;
  if (status.toLowerCase().indexOf('remediation') > -1) return SURVEY_ACTIONS_LABELS.viewRemediations;
  if (status.toLowerCase() === 'resolved') return SURVEY_ACTIONS_LABELS.viewQuestionnaire;
  return SURVEY_ACTIONS_LABELS.continueQuestionnaire;
};

const isThemeColor = (status: string) => {
  if (status === 'complete') return 'success';
  if (status === 'remediation') return 'error';
  if (status === 'submitted') return 'warning';
  if (status === 'cert') return 'primary';
  if (status === 'priority') return 'secondary';
  if (status === 'non-compliant') return 'nonCompliant';
  return (Object.keys(appTheme.palette).includes(status) ? status : 'primary') as PaletteKey;
};

export const surveyStatusColor = (survey?:Survey) => {
  if (!survey || survey.status === 'incomplete') return 'primary';
  let statusColor = 'primary';
  if (survey.status) return isThemeColor(survey.status);
  (survey.bestPracticeStatuses as BestPracticeStatus[])?.forEach(bestPracticeStatuses => {
    if (statuses.indexOf(bestPracticeStatuses.status) < statuses.indexOf(bestPracticeStatuses.status)) statusColor = bestPracticeStatuses.status;
  });
  return isThemeColor(statusColor);
};

export const assessmentActionLabel = (status?: string, user?: User) => {
  if (!user || !status) return { label: 'View Assessment', icon: <ViewIcon fontSize="small"/> };
  if (user.type === 'vendor_admin') {
    if (status === 'Pre-Assessment') return { label: 'Update Assessment', icon: <DynamicFormIcon fontSize="small"/> };
    if (status === 'Assessing') return { label: 'View and Comment', icon: <ChatIcon fontSize="small"/> };
    if (status === 'Submitted') return { label: 'View and Comment', icon: <ChatIcon fontSize="small"/> };
    if (status === 'Remediation') return { label: 'Remediate', icon: <AutoFixNormalIcon fontSize="small"/> };
    if (status === 'Complete') return { label: SURVEY_ACTIONS_LABELS.viewQuestionnaire, icon: <DynamicFormIcon fontSize="small"/> };
  }

  if (user.type === 'assessor') {
    if (status === 'Pre-Assessment') return { label: 'Review and Comment', icon: <RateReviewIcon fontSize="small"/> };
    if (status === 'Assessing') return { label: 'Assess', icon: <RateReviewIcon fontSize="small"/> };
    if (status === 'Submitted') return { label: 'View and Comment', icon: <ChatIcon fontSize="small"/> };
  }

  if (user.type === 'content_owner') {
    if (status === 'Pre-Assessment') return { label: SURVEY_ACTIONS_LABELS.viewQuestionnaire, icon: <DynamicFormIcon fontSize="small"/> };
    if (status === 'Assessing') return { label: SURVEY_ACTIONS_LABELS.viewQuestionnaire, icon: <DynamicFormIcon fontSize="small"/> };
    if (status === 'Submitted') return { label: SURVEY_ACTIONS_LABELS.viewQuestionnaire, icon: <DynamicFormIcon fontSize="small"/> };
    if (status === 'Remediation') return { label: 'Review Findings and Set Priority', icon: <RateReviewIcon fontSize="small"/> };
    if (status === 'Complete') return { label: SURVEY_ACTIONS_LABELS.viewQuestionnaire, icon: <DynamicFormIcon fontSize="small"/> };
  }

  if (user.type === 'tpn_admin') {
    if (status === 'Pre-Assessment') return { label: SURVEY_ACTIONS_LABELS.viewQuestionnaire, icon: <DynamicFormIcon fontSize="small"/> };
    if (status === 'Assessing') return { label: SURVEY_ACTIONS_LABELS.viewQuestionnaire, icon: <DynamicFormIcon fontSize="small"/> };
    if (status === 'Submitted') return { label: 'Review and Comment', icon: <RateReviewIcon fontSize="small"/> };
    if (status === 'Remediation') return { label: SURVEY_ACTIONS_LABELS.viewQuestionnaire, icon: <DynamicFormIcon fontSize="small"/> };
    if (status === 'Complete') return { label: SURVEY_ACTIONS_LABELS.viewQuestionnaire, icon: <DynamicFormIcon fontSize="small"/> };
  }
  return { label: 'View Assessment', icon: <ViewIcon fontSize="small"/> };
};

export const getRemediationCounts = (assessmentSurvey?: AssessmentSurvey) => {
  if (!assessmentSurvey) return 'Assessment Complete Pending Remediation';
  const theme = useTheme();

  return (
    <>
      <Typography sx={{ color: theme.palette.customThemeColors?.darkGrey }}>Assessment Complete Pending Remediation</Typography>
       <div style={{ width: '70px' }}>
        <BPRemediationCircles
          BPRemediationCount={assessmentSurvey.bestPracticeRemediationCount}
          IGRemediationCount={assessmentSurvey.implementationGuidanceRemediationCount}
        />
      </div>
    </>
  );
};

export const testSubmitAssessmentButton = (assessmentSurvey?: AssessmentSurvey, userType?: string) => {
  if (!assessmentSurvey) return;
  return (
    userType === 'assessor' &&
    assessmentSurvey.status?.toLowerCase() === 'assessing' &&
    assessmentSurvey.assessorAnswers.length >= (assessmentSurvey.survey as Survey).questionAnswers.length
  );
};

export const getAssessmentQuestionsAndAnswersCount = (bpStatus: BPStatusById, assessmentQuestionsStatus?: AssessmentQuestionStatusById, questionnaire?: Questionnaire) => {
  if (!assessmentQuestionsStatus || !questionnaire) {
    return {
      questionsCount: 0,
      answersCount: 0,
      hasBP: false,
      hasQuestion: true,
      isComplete: false,
      actualAssessmentAnswers: [],
    };
  }

  const assAnsAr = Object.keys(assessmentQuestionsStatus).map((aqs) => assessmentQuestionsStatus[aqs]);
  const actualAssessmentAnswers = assAnsAr.filter(
    (assAns) => assAns.answer && assAns.answer.length > 0 && assAns.id,
  );

  let questionsCount = 0;
  let answersCount = 0;
  let hasBP = false;
  let hasQuestion = false;

  questionnaire.domains.forEach((d) => {
    d.topics.forEach((t) => {
      t.bestPractices.forEach((bp) => {
        if (!bpStatus[bp.id] || bpStatus[bp.id].status === 'hide') return;
        if (bpStatus[bp.id].status !== 'hide') hasBP = true;

        questionsCount += bpStatus[bp.id].visibleQuestionsCount;
        bp.questions.forEach((bpQ) => {
          const questionHasAnswer = actualAssessmentAnswers.find(
            (aaa) =>
              aaa.answer &&
              (typeof aaa.question === 'number' ? aaa.question === bpQ.id : aaa.question.id === bpQ.id),
          );
          answersCount += questionHasAnswer ? 1 : 0;
          hasQuestion = true;
        });
      });
    });
  });

  const assessmentQStatusArr = Object.values(assessmentQuestionsStatus);
  const qCount = assessmentQStatusArr.length > questionsCount ? assessmentQStatusArr.length : questionsCount;
  return {
    questionsCount: qCount,
    answersCount,
    hasBP,
    actualAssessmentAnswers,
    hasQuestion,
    isComplete: questionsCount === answersCount || qCount === answersCount,
  };
};
