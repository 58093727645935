import React, { useState, useEffect, useCallback } from 'react';
import { useAppDispatch } from '../../../app/hooks';
import { DataRow, DataCell } from '../../Tables/DataList';
import { Domain } from '../../../interfaces/domain.interface';
import { updateQuestion, resetQuestion } from '../../../app/store/questionSlice';
import StandardDialog, { StandardDialogActions } from '../../Modals/StandardDialog';
import { createInclusionConfiguration, deleteInclusionConfiguration } from '../../../app/store/inclusionConfigurationSlice';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import { FormControl, InputLabel, MenuItem, Select, Tooltip } from '@mui/material';
import { useAppSelector } from '../../../app/hooks';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { fetchQuestionnaire, fetchTopics, fetchBestPractices } from '../../../services/questionnaires';
import { BestPractice } from '../../../interfaces/bestPractice.interface';
import { IncludedInLogic } from '../../Questions/Question';
import { Answer } from '../../../interfaces/answer.interface';

export default function ScopeModal({ includedInLogic, answer, isOpen, closeModal, incQId, incAId }: {
  answer?: string;
  includedInLogic: IncludedInLogic[];
  isOpen: boolean;
  closeModal: () => void;
  incQId: number;
  incAId: number;
}) {
  const dispatch = useAppDispatch();
  const { results: questionnaires, questionnaire } = useAppSelector(state => state.questionnaires);

  const [questionnaireSlug, setQuestionnaireSlug] = useState<number | string | undefined>(questionnaire?.id);
  const [selectedDomainSlug, setSelectedDomainSlug] = useState<string | undefined>();
  const [selectedQuestions, setSelectedQuestions] = useState<{ [key: number]: boolean }>({});
  const [bpSlug, setBpSlug] = useState<string>('');

  const [domains, setDomains] = useState<Domain[] | null>(null);
  const [topics, setTopics] = useState<Domain | null>(null);
  const [bestPractice, setBestPractice] = useState<BestPractice[] | string | null>(null); 
  const [bestPracticeQuestions, setBestPracticeQuestions] = useState<BestPractice | null>(null);

  const handleFetchDomains = useCallback(async (slug: string) => {
    setBestPracticeQuestions(null);
    setBestPractice(null);
    const resp = await fetchQuestionnaire(slug);
    setDomains(resp.domains);
  }, [questionnaireSlug]);

  const handleAddScopeClose = useCallback(() => {
    setSelectedDomainSlug(undefined);
    setQuestionnaireSlug(undefined);
    setBestPracticeQuestions(null);
    setSelectedQuestions({});
    setBpSlug('');
    setBestPractice(null);
    setDomains(null);
    setTopics(null);
    closeModal();
  }, []);

  const handleFetchTopics = useCallback(async (slug: string) => {
    setSelectedDomainSlug(slug);
    setBestPracticeQuestions(null);
    setBestPractice(null);
    const fetchedTopics = await fetchTopics(slug);
    setTopics(fetchedTopics);
  }, []);

  const handleFetchBestPracticeQuestions = useCallback(async (slug: string) => {
    setBpSlug(slug);
    const fetchedQuestions = await fetchBestPractices(slug);
    setBestPracticeQuestions(fetchedQuestions);
  }, []);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedQuestions({
      ...selectedQuestions,
      [event.target.name]: event.target.checked,
    });
  };

  const handleQuestionnaireSelect = (slug: string) => {
    setQuestionnaireSlug(slug);
    handleFetchDomains(slug);
    setTopics(null);
    setBestPracticeQuestions(null);
    setBestPractice(null);
  };

  const handleSaveScopes = useCallback(async () => {
    if (!bestPracticeQuestions?.questions) return;

    // For each selected question, create an inclusion configuration and update the question
    const promises = bestPracticeQuestions.questions
      .filter((question) => {
        const isSelected = selectedQuestions[question.id];
        const isIncluded = includedInLogic.some((inc) => inc.id === question.id);
        return (isSelected && !isIncluded) || (!isSelected && isIncluded);
      })
      .map(async (question) => {
        if (selectedQuestions[question.id]) {
          const incResp = await dispatch(createInclusionConfiguration({ question: incQId, answer: incAId })) as any;
          if (!incResp.payload?.id) return;
          return dispatch(updateQuestion({ ...question, inclusions: [...question.inclusions, incResp.payload.id] }));
        } else {
          const validInc = includedInLogic.find((inc) => inc.id === question.id && inc.inclusions.some((inclusion) => (inclusion.answer as Answer)?.id === incAId));
          if (!validInc) return;
          const inclusionId = validInc.inclusions.find((inclusion) => (inclusion.answer as Answer)?.id === incAId)?.id;
          if (!inclusionId) return;
          return dispatch(deleteInclusionConfiguration(inclusionId));
        }
      });

    Promise.all(promises)
      .then(() => {
        dispatch(resetQuestion());
        handleAddScopeClose();
      })
      .catch((error) => {
        console.error('Error updating questions:', error);
      });
  }, [selectedQuestions, bestPracticeQuestions, incQId, incAId, closeModal]);

  useEffect(() => {
    if (!bestPracticeQuestions) return;
    const initialSelectedQuestions = bestPracticeQuestions.questions.reduce<{ [key: number]: boolean }>((acc, question) => {
      const hasAnswer = includedInLogic.some((inc) => inc.inclusions.some((inclusion: any) => inclusion.answer.id === incAId) && inc.id === question.id);
      acc[question.id] = hasAnswer;
      return acc;
    }, {});
    setSelectedQuestions(initialSelectedQuestions);
  }, [bestPracticeQuestions]);

  return (
        <StandardDialog title={'Questionnaire Scope Reducer'} handleClose={handleAddScopeClose} isOpen={isOpen}>
        <Typography variant='h6' sx={{ marginBottom: '15px' }}>
            Answer: {answer}</Typography>
        {!!questionnaires?.length && <>
        <FormControl sx={{ marginBottom: '10px' }} fullWidth>
        <InputLabel id="questionnaire-label">Questionnaire</InputLabel>
        <Select
            labelId="questionnaire-label"
            id="questionnaire"
            value={questionnaireSlug || ''}
            label="Questionnaire"
            onChange={(e) => handleQuestionnaireSelect(e.target.value as string)}
        >
            {questionnaires.map(q=> <MenuItem key={q.slug} value={q.slug}>{q.title} {q.version}</MenuItem>)}
        </Select>
        </FormControl>
        </>}
        {domains && domains.length > 0 && (
        <FormControl sx={{ marginBottom: '10px' }} fullWidth>
        <InputLabel id="domain-label">Domain</InputLabel>
        <Select
            labelId="domain-label"
            id="domain"
            value={selectedDomainSlug || ''}
            onChange={(e) => handleFetchTopics(e.target.value)}
            label="Domain"
        >
            {domains.map((domain, idx) => <MenuItem key={idx} value={domain.slug}>{domain.title}</MenuItem>)}
        </Select>
        </FormControl>
        )}
        {topics && topics.topics.length > 0 && (
        <FormControl sx={{ marginBottom: '10px' }} fullWidth>
        <InputLabel id="topic-label">Topic</InputLabel>
        <Select
            labelId="topic-label"
            id="topic"
            value={bestPractice || ''}
            onChange={(e) => { setBestPractice(e.target.value); setBestPracticeQuestions(null);}}
            label="Topic"
        >
            {topics.topics.map((topic: any, idx: number) => <MenuItem key={idx} value={topic?.bestPractices}>{topic.title}</MenuItem>)}
        </Select>
        </FormControl>
        )}
        {bestPractice && Array.isArray(bestPractice) && bestPractice.length > 0 && (
        <FormControl sx={{ marginBottom: '10px' }} fullWidth>
        <InputLabel id="best-practice-label">Best Practice</InputLabel>
        <Select
            labelId="best-practice-label"
            id="best-practice"
            value={bpSlug}
            onChange={(e) => handleFetchBestPracticeQuestions(e.target.value as string)}
            label="Best Practice"
        >
            {bestPractice.map((bp: BestPractice, idx: number) => <MenuItem key={idx} value={bp.slug}>{bp.title}</MenuItem>)}
        </Select>
        </FormControl>
        )}
        { bestPracticeQuestions && bestPracticeQuestions.questions.length > 0 && (
        <FormControl component="fieldset" fullWidth sx={{ marginBottom: '10px' }}>
        <Typography variant="h6" sx={{ marginTop: '10px' }}>Best Practice Questions</Typography>
        <DataRow header>
        <DataCell xs={9}>Question</DataCell>
        <DataCell xs={3} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
            De-scope
            <Tooltip title="Check this box to reduce the Questionnaire Scope and make the Best Practice question visible only when the Baseline Answer at the top is selected.">
                <IconButton size="small" sx={{ marginLeft: 0 }}>
                <HelpOutlineIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        </DataCell>
        </DataRow>
        {bestPracticeQuestions.questions.map((bpq) => (
        <DataRow key={bpq.id}>
            <DataCell xs={9}>{bpq.title}</DataCell>
            <DataCell xs={3}>
                <Checkbox
                    checked={selectedQuestions[bpq.id] || false}
                    onChange={handleCheckboxChange}
                    name={bpq.id.toString()}
                />
            </DataCell>
        </DataRow>
        ))}
        </FormControl>
        )}
        <StandardDialogActions>
        <Button variant="outlined" onClick={handleAddScopeClose}>Cancel</Button>
        <Button variant="contained" onClick={handleSaveScopes}color='primary'>Save</Button>
        </StandardDialogActions>
        </StandardDialog>
  );
}