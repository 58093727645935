import User, { Company } from '../interfaces/users.interface';

export default function canAdminCompany(user: User | undefined, company: Company) {
  if (!user) return false;
  if (user.type == 'tpn_admin') return true;
  if (!company.employees?.length) return false;

  const companyEmployeeIds: number[] = company.employees.map((employee: User) => employee.id);

  if (!companyEmployeeIds.includes(user.id)) return false;
  const adminUser = company.admins?.find((admin: User) => admin.id == user.id);

  return adminUser != undefined;
}

const allowedEmails = new Set([
  'crystal_pham+sp@motionpictures.org',
  'sami_iglesias+sp@motionpictures.org',
  'melody_giambastiani+sp@motionpictures.org',
  'terri_davies+sp@motionpictures.org',
  'kari_grubin+sp@motionpictures.org',
  'amely_mazumdar+sp@motionpictures.org',
  'andrew_carl@motionpictures.org',
  'caroham29+spmay@gmail.com',
  'caroham29+sp3may@gmail.com',
  'caroham29+sp2may@gmail.com',
]);

export const canStartFiveThreeSurvey = (user: User | undefined) => {
  if (!user) return false;
  return allowedEmails.has(user.email.toLocaleLowerCase());
};
