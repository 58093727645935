import React, { useState, useEffect, useRef } from 'react';
import debounce from 'lodash/debounce';
import { useAppDispatch } from '../../app/hooks';
import { DataRow, DataCell, DataRowActions, DraggableItem } from '../Tables/DataList';
import { Answer } from '../../interfaces/answer.interface';
import { Question } from '../../interfaces/question.interface';
import StandardDialog, { StandardDialogActions } from '../Modals/StandardDialog';
import ScopeModal from './Scope/ScopeModal';

import { useForm, FieldValues, Controller } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import { IncludedInLogic } from '../Questions/Question';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import { updateAnswer, deleteAnswer } from '../../app/store/answerSlice';

export default function AnswerListItem(props: { includedInLogic?: IncludedInLogic[], answer: Answer, canEdit?: boolean, question: Question, idx: number, moveItem?: any, dropItem?: any, removeAnswer: any, updateQuestion: any }) {
  const [answer, setAnswer] = useState(props.answer);
  const dispatch = useAppDispatch();
  const [deleteAnswerOpen, setDeleteAnswerOpen] = useState(false);
  const handleDeleteAnswerOpen = () => setDeleteAnswerOpen(true);
  const handleDeleteAnswerClose = () => setDeleteAnswerOpen(false);

  const {
    handleSubmit,
    register,
    control,
    setValue,
    getValues,
    watch,
  } = useForm({
    defaultValues: answer,
  });

  const onDeleteSubmit = async () => {
    dispatch(deleteAnswer(answer.id));
    props.removeAnswer(answer.id);
    handleDeleteAnswerClose();
  };

  const debounceUpdateAnswer = useRef(debounce(() => {
    const data:FieldValues = getValues();
    dispatch(updateAnswer(data));
    setAnswer({ ...answer, ...data });
  }, 1000)).current;

  useEffect(() => {
    const subscription = watch(() => debounceUpdateAnswer());
    return () => subscription.unsubscribe();
  }, [watch]);

  const [showAddScopeModal, setShowAddScopeModal] = useState(false);
  const handleAddScopeOpen = React.useCallback(() => setShowAddScopeModal(true), []);
  const handleCloseModal = () => setShowAddScopeModal(false);  

  const renderRow = () => {
    return (
      <DataRow>
        <DataCell xs={7} p={.01} sx={{ display: 'flex', alignItems: 'center' }}>
          { props.moveItem  && props.canEdit && <DragIndicatorIcon sx={{ cursor: 'grab', mr: '.25em' }} /> }
          <TextField
            required
            id='text'
            label='Text'
            disabled={props.canEdit === false}
            maxRows={2}
            multiline
            sx={{ width: 'calc(100% - 17px)' }}
            fullWidth
            {...register('text')}
          />
        </DataCell>
        <DataCell xs={2} p={.01}>
          <Button sx={{ width: '100%' }} variant="text" startIcon={<AltRouteIcon />} onClick={handleAddScopeOpen}>SCOPE</Button>
          <ScopeModal 
            includedInLogic={props.includedInLogic || []}
            incQId={props.question.id}
            incAId={props.answer.id}
            answer={props.answer.text}
            isOpen={showAddScopeModal}
            closeModal={handleCloseModal}/>
        </DataCell>
        <DataCell xs={3} p={.01}>
          <Controller
            control={control}
            name="isCompliant"
            render={({ field: { value } }) => (
              <Checkbox disabled={props.canEdit === false}  checked={value ? true : false} onChange={(e) => { setValue('isCompliant', e.target.checked); }} />
            )}
          />
          <DataRowActions>
          {props.canEdit && <IconButton size="small" name="Delete Answer" onClick={(e)=> {e.preventDefault(); handleDeleteAnswerOpen(); }}><DeleteIcon /></IconButton>}
            <StandardDialog title={`Remove ${answer.text} from question ${props.question?.title}`} handleClose={handleDeleteAnswerClose} isOpen={deleteAnswerOpen}>
              <Typography>Are you sure you want to remove {answer.text} from question {props.question?.title}?</Typography>
              <StandardDialogActions component="form" method="post" onSubmit={handleSubmit(onDeleteSubmit)}>
                <Button variant="outlined" onClick={() => handleDeleteAnswerClose()}>Cancel</Button>
                <Button type="submit"variant="contained" color="error">Remove</Button>
              </StandardDialogActions>
            </StandardDialog>
          </DataRowActions>
        </DataCell>
      </DataRow>
    );
  };

  return (
    <>
      { props.moveItem && props.canEdit ? 
        <DraggableItem
            key={props.idx}
            index={props.idx}
            id={answer.id}
            moveItem={props.moveItem}
            dropItem={props.dropItem}
          >
          {renderRow()}
        </DraggableItem>
        :
        <>{renderRow()}</>
      }
    </>
  );
}